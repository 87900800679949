/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-15 16:43:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 16:36:02
 */
export const columns = [
  // {
  //   title: '大区',
  //   dataIndex: 'mhiacAreaName',
  //   key: 'mhiacAreaName',
  //   /*width:150,*/
  //   align:'center'
  // },{
  //   title: '事务所',
  //   dataIndex: 'firmName',
  //   key: 'firmName',
  //   /*width:150,*/
  //   align:'center'
  // },
//   {
//     title: '经销商',
//     dataIndex: 'dealerName',
//     key: 'dealerName',
//     align:'center'
// },
{
    title: '折扣名称',
    dataIndex: 'rebateName',
    key: 'rebateName',
    /*width:150,*/
    align:'center'
},
{
    title: '折扣金额',
    dataIndex: 'rebateAmount',
    key: 'rebateAmount',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'rebateAmount' }
},
{
    title: '剩余金额',
    dataIndex: 'remainingAmount',
    key: 'remainingAmount',
    // width:150,
    align:'center',
    scopedSlots: { customRender: 'remainingAmount' }
},
{
    title: '有效期',
    dataIndex: 'startTime',
    key: 'startTime',
    width:220,
    align:'center',
    scopedSlots: { customRender: 'startTime'}

},
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align:'center',
    scopedSlots: { customRender: 'status'}
  },
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:120,
    align:'center',
    ellipsis:true
},
// {
//     title: '是否启用',
//     dataIndex: 'flagEnable',
//     key: 'flagEnable',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true,
//     scopedSlots: { customRender: 'flagEnable'}
// },
{
  title: '适用范围',
  dataIndex: 'categoryNameStr',
  key: 'categoryNameStr',
  width:200,
  align:'left',
  ellipsis:true
},
{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
}
]
